/** @module components/ReassignRepositoriesTable */
import React from 'react';
import { Table, Tooltip } from 'antd';
import { ReactComponent as RepositoryIcon } from 'assets/icons/repository.svg';
import { ReactComponent as TagIcon } from 'assets/icons/tag.svg';
import { LicenseType, ProtectionType, Repository } from 'services/metadata';
import { useTranslation } from 'react-i18next';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import { ReactComponent as ProgressIndicatorIcon } from 'assets/icons/progress-indicator.svg';
import { RepoReassignmentStatus } from 'components/ReassignRepositoriesPage/types';
import { SubmitType } from 'components/ReassignOwnerSection/types';
import { GlobalPermissions } from 'services/metadata/types/Permissions';
import { NameField } from '../NameField';
import ProtectionTag from '../ProtectionTag';
import { Props } from './types';
import './styles.scss';

function ReassignRepositoriesTable(props: Props): JSX.Element {
  const { t } = useTranslation();
  const {
    admin,
    me,
    repositories,
    selectedRepositoriesToReassign,
    setSelectedRepositoriesToReassign,
    loadings,
    reassignedRepos,
    failedReassignedRepos,
    errors,
  } = props;

  const selectedRowKeys = selectedRepositoriesToReassign.map((repo) => repo.id);

  function onSelectChange(newSelectedRowKeys: React.ReactText[], selectedRows: Repository[]): void {
    setSelectedRepositoriesToReassign(selectedRows);
  }

  const repoReassignmentStatus = (repository: Repository): RepoReassignmentStatus | null => {
    const { id } = repository;
    const idWithAddRequest = `COLLABORATORS_ADD_${id}`;
    const idWithUpdateRequest = `COLLABORATORS_UPDATE_${id}`;
    if (loadings[idWithAddRequest] || loadings[idWithUpdateRequest]) {
      return RepoReassignmentStatus.IN_PROGRESS;
    }
    if (failedReassignedRepos.has(id)) {
      return RepoReassignmentStatus.FAILED;
    }
    if (reassignedRepos.has(id)) {
      return RepoReassignmentStatus.SUCCESSFUL;
    }
    return null;
  };

  const reassignAllowed = (repository: Repository): boolean => {
    if (!admin) return true;

    if (!me) return false;

    if (me.permissions) {
      const globalPermission = me.permissions.global;
      if (globalPermission
        && globalPermission.includes(GlobalPermissions.GLOBAL_UPDATE_COLLABORATOR)) {
        return true;
      }
    }

    if (!me.user || !repository.business) return false;

    return me.user.business.id === repository.business.id;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record: Repository) => ({
      disabled: repoReassignmentStatus(record) === RepoReassignmentStatus.SUCCESSFUL
        || !reassignAllowed(record),
    }),
  };

  const rowClassName = (repository: Repository): string => {
    const status = repoReassignmentStatus(repository);
    if (status === RepoReassignmentStatus.IN_PROGRESS) {
      return 'in-progress';
    }
    if (status === RepoReassignmentStatus.SUCCESSFUL || !reassignAllowed(repository)) {
      return 'inactive-row';
    }
    return '';
  };

  const renderStatusIcon = (repository: Repository): JSX.Element | null => {
    const status = repoReassignmentStatus(repository);
    const { id } = repository;
    const idWithAddRequest = `COLLABORATORS_ADD_${id}`;
    const idWithUpdateRequest = `COLLABORATORS_UPDATE_${id}`;

    if (status === RepoReassignmentStatus.SUCCESSFUL) {
      return <SuccessIcon className="success-icon" data-test-id="success-icon" data-repo-id={id} />;
    }

    if (status === RepoReassignmentStatus.FAILED) {
      const reassignmentData = failedReassignedRepos.get(id) || { type: '' };
      const idWithRequest = reassignmentData.type === SubmitType.ADD
        ? idWithAddRequest : idWithUpdateRequest;
      const message = errors[idWithRequest];
      return (
        <Tooltip
          placement="leftTop"
          title={message}
          data-test-id="tooltip-error-icon"
        >
          <ErrorIcon className="error-icon" data-test-id="error-icon" data-repo-id={id} />
        </Tooltip>
      );
    }

    if (status === RepoReassignmentStatus.IN_PROGRESS) {
      return <ProgressIndicatorIcon className="spin" data-test-id="progress-indicator-icon" />;
    }
    return null;
  };

  return (
    <Table
      dataSource={repositories}
      rowSelection={rowSelection}
      rowClassName={rowClassName}
      rowKey="id"
      size="middle"
      className="ReassignRepositoriesTable"
      data-test-id="table"
      pagination={false}
    >
      <Table.Column
        title={t('ReassignRepositoriesPage.column.name', 'Name')}
        dataIndex="name"
        className="name-column"
        render={(name: string): JSX.Element => (
          <NameField
            text={name}
            icon={(
              <RepositoryIcon
                width={10}
                height={10}
                className="icon icon-indicator repository-icon"
              />
            )}
            editing={false}
            onConfirm={(): void => { }}
            onCancel={(): void => { }}
            updateFileParent={(): void => { }}
          />
        )}
        data-test-id="column-name"
      />
      <Table.Column
        title={t('ReassignRepositoriesTable.column.business', 'Business')}
        key="business"
        className="business-column"
        render={(repository: Repository): string => (repository.business ? repository.business.displayName : '')}
        data-test-id="column-business"
      />
      <Table.Column
        title={t('ReassignRepositoriesTable.column.protections', 'Protections')}
        dataIndex="protections"
        className="protections-column"
        render={(protections: LicenseType[], repository: Repository): JSX.Element => (
          <span>
            {protections.map((protection: LicenseType) => (
              <span className="protection-tag" key={protection}>
                <ProtectionTag
                  protection={protection}
                  key={protection}
                  data-test-id="protection-tag"
                />
              </span>
            ))}
            {repository.isLimited && (
              <span className="protection-tag" key={ProtectionType.LIMITED}>
                <ProtectionTag
                  protection={ProtectionType.LIMITED}
                  key={ProtectionType.LIMITED}
                  data-test-id="protection-tag-limited"
                />
              </span>
            )}
          </span>
        )}
        data-test-id="column-protections"
      />
      <Table.Column
        title={t('ReassignRepositoriesTable.column.tags', 'Tags')}
        dataIndex="tags"
        className="tags-column"
        render={(tags: string[]): JSX.Element => (
          <span>
            {tags && tags.map((tag: string) => (
              <span className="tag-container" key={tag}>
                <div key={tag} data-test-id="tag" className="tag">
                  <TagIcon className="tag-icon" />
                  {tag}
                </div>
              </span>
            ))}
          </span>
        )}
        data-test-id="column-tags"
      />
      <Table.Column
        title={t('ReassignRepositoriesTable.column.updatedOn', 'Updated On')}
        key="updatedOn"
        className="updated-on-column"
        render={(repository: Repository): JSX.Element => (
          <span>
            {new Date(repository.lastUpdatedOn).toLocaleString(navigator.language)}
          </span>
        )}
        data-test-id="column-updated-on"
      />
      <Table.Column
        title={t('ReassignRepositoriesTable.column.owner', 'Owner')}
        key="owner"
        render={(repository: Repository): JSX.Element => {
          let reassignmentData = null;
          if (RepoReassignmentStatus.SUCCESSFUL === repoReassignmentStatus(repository)) {
            reassignmentData = reassignedRepos.get(repository.id);
          }
          const ownerName = reassignmentData
            ? reassignmentData.newOwnerName : repository.owner.name;
          return <span data-test-id="owner-name">{ownerName}</span>;
        }}
        data-test-id="column-owner"
      />
      <Table.Column
        title=""
        className="status-column"
        render={(repository: Repository): JSX.Element => (
          <div className="status-icon-container">
            {renderStatusIcon(repository)}
          </div>
        )}
        data-test-is="column-status"
      />
    </Table>
  );
}

export default ReassignRepositoriesTable;
