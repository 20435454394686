/** @module store/user-repositories */
import { Action } from 'redux';
import { Repository } from 'services/metadata';

export type State = {
  [userId: string]: {
    entries: Repository[];
    lastUpdated?: Date;
    callFailed?: boolean;
  };
};

export enum UserRepositoriesActionType {
  USERS_REPOSITORIES_GET_REQUEST = 'USERS_REPOSITORIES_GET_REQUEST',
  USERS_REPOSITORIES_GET_SUCCESS = 'USERS_REPOSITORIES_GET_SUCCESS',
  USERS_REPOSITORIES_GET_ERROR = 'USERS_REPOSITORIES_GET_ERROR',
  USERS_REPOSITORIES_DELETE_REQUEST = 'USERS_REPOSITORIES_DELETE_REQUEST',
  USERS_REPOSITORIES_DELETE_SUCCESS = 'USERS_REPOSITORIES_DELETE_SUCCESS',
  USERS_REPOSITORIES_DELETE_ERROR = 'USERS_REPOSITORIES_DELETE_ERROR',
  USERS_REPOSITORIES_RENAME_REQUEST = 'USERS_REPOSITORIES_RENAME_REQUEST',
  USERS_REPOSITORIES_RENAME_SUCCESS = 'USERS_REPOSITORIES_RENAME_SUCCESS',
  USERS_REPOSITORIES_RENAME_ERROR = 'USERS_REPOSITORIES_RENAME_ERROR',
}

export type UsersRepositoriesGetRequestAction = Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_GET_REQUEST
>;

export interface UsersRepositoriesGetSuccessAction extends Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_GET_SUCCESS
> {
  payload: {
    userId: string;
    repositories: Repository[];
  };
}

export interface UsersRepositoriesGetErrorAction extends Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type UsersRepositoriesDeleteRequestAction = Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_REQUEST
>;

export interface UsersRepositoriesDeleteSuccessAction extends Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_SUCCESS
> {
  payload: {
    repository: Repository;
    userId: string;
    showNotification?: boolean;
  };
}

export interface UsersRepositoriesDeleteErrorAction extends Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_DELETE_ERROR
> {
  payload: {
    error: Error;
    showNotification?: boolean;
  };
}

export type UsersRepositoriesRenameRequestAction = Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_REQUEST
>;

export interface UsersRepositoriesRenameSuccessAction extends Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_SUCCESS
> {
  payload: {
    userId: string;
    repoId: string;
    name: string;
  };
}

export interface UsersRepositoriesRenameErrorAction extends Action<
  UserRepositoriesActionType.USERS_REPOSITORIES_RENAME_ERROR
> {
  payload: {
    error: Error;
  };
}
export type UsersAction = UsersRepositoriesGetErrorAction
                        | UsersRepositoriesGetSuccessAction
                        | UsersRepositoriesGetErrorAction
                        | UsersRepositoriesDeleteErrorAction
                        | UsersRepositoriesDeleteRequestAction
                        | UsersRepositoriesDeleteSuccessAction;
