/** @module services/metadata */
import { API } from './types/API';
import { Announcement } from './types/Announcement';
import { Collaborator } from './types/Collaborator';
import { CollaboratorIdentity } from './types/CollaboratorIdentity';
import { Item } from './types/Item';
import { ItemDetails } from './types/ItemDetails';
import { Me } from './types/Me';
import { MetadataError } from './types/MetadataError';
import { ExtendedMetadataError } from './types/MetadataErrorResponse';
import { RegistrationState } from './types/RegistrationState';
import { Repository } from './types/Repository';
import { User } from './types/User';
import { Tag } from './types/Tag';
import { Business } from './types/Business';
import { Role } from './types/Role';
import { Snapshot, SnapshotExport, SnapshotExportSegments } from './types/Snapshot';
import { EvictionStatus } from './types/EvictionStatus';
import { Domains } from './types/Domains';

/**
 * A client that communicates with the REDShare metadata service
 * using the fetch function.
 */
class FetchClient implements API {
  private prefix: string;

  /**
   * Constructs a new client with the given API
   * level.
   *
   * @param apiLevel The API level this service uses
   */
  constructor(apiLevel = '1.0') {
    this.prefix = `/metadata/${apiLevel}`;
  }

  getAnnouncements(): Promise<Announcement[]> {
    const uri = `${this.prefix}/announcements`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepositories(): Promise<Repository[]> {
    const uri = `${this.prefix}/repositories`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  createRepository(
    name: string,
    fedRamp: number,
    isCdi: boolean,
    isEc: boolean,
    businessWizardId: string,
    isUsOnly: boolean,
    isUkLicense: boolean,
    isLicenseAvailable: boolean,
    isUsCitizen: boolean,
    isDoC: boolean,
    tags: string[],
    fullTextSearch: boolean,
  ): Promise<{ id: string; name: string }> {
    const uri = `${this.prefix}/repositories`;
    const body = JSON.stringify({
      name,
      fedRamp,
      isCdi,
      isEc,
      businessWizardId,
      isUsOnly,
      isUkLicense,
      isLicenseAvailable,
      isUsCitizen,
      isDoC,
      tags,
      fullTextSearch,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  updateRepository(
    repoId: string,
    name: string,
    fullTextSearchEnabled?: boolean,
  ): Promise<Response> {
    const uri = `${this.prefix}/repositories/${repoId}`;
    const body = JSON.stringify({
      name,
      fullTextSearchEnabled,
    });
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  evictRepository(
    repoId: string,
    evicted: boolean,
  ): Promise<Response> {
    const uri = `${this.prefix}/repositories/${repoId}`;
    const body = JSON.stringify({
      evicted,
    });
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getRepoEvictionStatus(repoId: string): Promise<EvictionStatus> {
    const uri = `${this.prefix}/repositories/${repoId}/evictions`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  deleteRepository(repoId: string): Promise<Response> {
    const uri = `${this.prefix}/repositories/${repoId}`;
    const options = {
      method: 'DELETE',
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getCollaborators(repoId: string): Promise<Collaborator[]> {
    const uri = `${this.prefix}/repositories/${repoId}/collaborators`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepoZipDownloadUrl(repoId: string): Promise<string> {
    const uri = `${this.prefix}/repositories/${repoId}/zip`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((json: { url: string }) => json.url);
  }

  getRepositorySnapshots(repoId: string): Promise<Snapshot[]> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepositorySnapshot(repoId: string, snapshotId: string): Promise<Snapshot> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots/${snapshotId}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepositorySnapshotExports(repoId: string, snapshotId: string): Promise<SnapshotExport[]> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots/${snapshotId}/exports`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepositorySnapshotExportSegments(
    repoId: string,
    snapshotId: string,
    exportId: string,
  ): Promise<SnapshotExportSegments> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots/${snapshotId}/exports/${exportId}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepositorySnapshotExportSegmentDownloadUrl(
    repoId: string,
    snapshotId: string,
    exportId: string,
    segmentNumber: number,
  ): Promise<string> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots/${snapshotId}/exports/${exportId}/segments/${segmentNumber}/content`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((json: { url: string }) => json.url);
  }

  createRepositorySnapshot(repoId: string, snapshotName: string): Promise<{ id: string }> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots/`;
    const body = JSON.stringify({
      name: snapshotName,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  createRepositorySnapshotExport(
    repoId: string,
    snapshotId: string,
    maxSegmentSize: number,
  ): Promise<{ id: string }> {
    const uri = `${this.prefix}/repositories/${repoId}/snapshots/${snapshotId}/exports`;
    const body = JSON.stringify({
      targetMaxSegmentSize: maxSegmentSize,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  /**
   * Since API version 2.0
   */
  addCollaborator(identity: string, role: string, repoId: string): Promise<Collaborator> {
    const uri = `${this.prefix}/collaborators`;
    const body = JSON.stringify({
      identity,
      role,
      repoId,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((response: {[key: string]: Collaborator}) => response[identity]);
  }

  addCollaborators(
    repoId: string,
    collaborators: Array<CollaboratorIdentity>,
  ): Promise<object> {
    const uri = `${this.prefix}/repositories/${repoId}/collaborators`;
    const body = JSON.stringify(collaborators);
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((response: { [key: string]: { status: string; reason: { description: string } } }) => {
        collaborators.forEach((collaborator) => {
          if (response[collaborator.identity].status !== 'ACCEPTED') {
            throw new Error(response[collaborator.identity].reason.description);
          }
        });
        return response;
      });
  }

  updateCollaborator(collabId: string, data: object): Promise<Response> {
    const uri = `${this.prefix}/collaborators/${collabId}`;
    const body = JSON.stringify({
      ...data,
    });
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  /**
   * Since API level 2.0
   */
  deleteCollaborator(collabId: string): Promise<Response> {
    const uri = `${this.prefix}/collaborators/${collabId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  deleteCollaborators(collabIds: Array<string>): Promise<Response> {
    let uri = `${this.prefix}/collaborators?`;
    collabIds.forEach((collabId) => {
      uri += `id=${collabId}&`;
    });
    uri = uri.substring(0, uri.length - 1);
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getRepositoryLicenses(repoId: string): Promise<string[]> {
    const uri = `${this.prefix}/repositories/${repoId}/licenses`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  addRepositoryLicenses(
    repoId: string,
    licenses: Array<string>,
  ): Promise<object> {
    let uri = `${this.prefix}/repositories/${repoId}/licenses?`;
    licenses.forEach((license) => {
      uri += `license=${license}&`;
    });
    uri.substring(0, uri.length - 1);
    const options = {
      method: 'POST',
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  deleteRepositoryLicenses(
    repoId: string,
    licenses: Array<string>,
  ): Promise<object> {
    let uri = `${this.prefix}/repositories/${repoId}/licenses?`;
    licenses.forEach((license) => {
      uri += `license=${license}&`;
    });
    uri.substring(0, uri.length - 1);
    const options = {
      method: 'DELETE',
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getFile(fileId: string): Promise<object> {
    const uri = `${this.prefix}/files/${fileId}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  updateFile(fileId: string, name: string, parentId: string): Promise<ItemDetails> {
    const uri = `${this.prefix}/files/${fileId}`;
    const body = JSON.stringify({
      name,
      parentId,
    });
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getFileUrl(fileId: string): string {
    const uri = `${this.prefix}/files/${fileId}/content`;
    return uri;
  }

  getFilePreviewUrl(fileId: string): Promise<string> {
    const uri = `${this.prefix}/files/${fileId}/preview`;
    return fetch(uri)
      .then((response: Response) => {
        if (response.status === 204) {
          throw new Error('Unsupported file type');
        }
        return response;
      })
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((json: { link: string }) => json.link);
  }

  getFolder(folderId: string, children = true, actions = true, ancestors = true): Promise<Item> {
    const uri = `${this.prefix}/folders/${folderId}?children=${children}&actions=${actions}&ancestors=${ancestors}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  createFolder(name: string, parentId: string): Promise<ItemDetails> {
    const uri = `${this.prefix}/folders`;
    const body = JSON.stringify({
      name,
      parentId,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  updateFolder(
    folderId: string,
    name: string,
    parentId: string,
  ): Promise<ItemDetails> {
    const uri = `${this.prefix}/folders/${folderId}`;
    const body = JSON.stringify({
      name,
      parentId,
    });
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getFolderUploadUrl(folderId: string): Promise<string> {
    const uri = `${this.prefix}/folders/${folderId}/upload`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((json: { url: string }) => json.url);
  }

  getFolderChildren(folderId: string): Promise<object> {
    const uri = `${this.prefix}/folders/${folderId}/children`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  registerMe(): Promise<Me | undefined> {
    const uri = `${this.prefix}/users/register`;
    const options = {
      method: 'POST',
    };
    return fetch(uri, options)
      .then(async (response: Response) => {
        const contentType = response.headers.get('content-type');
        if (typeof contentType === 'string' && contentType.startsWith('application/json')) {
          if (response.status === 409) {
            const json = await response.json();
            return {
              ...json,
              registrationState: RegistrationState.ALREADY_REGISTERED,
            };
          }
          return FetchClient.handleError(response);
        }
        return FetchClient.handleError(response);
      });
  }

  getMe(): Promise<Me> {
    const uri = `${this.prefix}/me`;
    return fetch(uri)
      .then(async (response: Response) => {
        const contentType = response.headers.get('content-type');
        if (typeof contentType === 'string' && contentType.startsWith('application/json')) {
          const json = await response.json();
          switch (response.status) {
            case 200:
              return {
                ...json,
                registrationState: json.user.preregistered
                  ? RegistrationState.PREREGISTERED
                  : RegistrationState.REGISTERED,
              };
            case 401: {
              const { cookie } = document;
              const hasCookie = cookie.includes('mod_auth_openidc_session');
              if (hasCookie) {
                return { registrationState: RegistrationState.UNAUTHORIZED };
              }
              return { registrationState: RegistrationState.LOGGEDOUT };
            }
            case 403:
              return { registrationState: RegistrationState.BLOCKED };
            case 404:
              return { registrationState: RegistrationState.UNREGISTERED };
            case 409:
              return {
                ...json,
                registrationState: RegistrationState.PREREGISTERED,
              };
            default:
              throw new Error(json.description);
          }
        }
        throw new Error(response.statusText);
      });
  }

  searchUsers(searchTerm: string): Promise<User[]> {
    const uri = `${this.prefix}/users?identity=${searchTerm}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  deleteItem(id: string): Promise<Response> {
    const uri = `${this.prefix}/files/${id}`;
    const options = {
      method: 'DELETE',
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getRepositoriesTrash(): Promise<Repository[]> {
    const uri = `${this.prefix}/trash/repositories`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRepositoryTrash(repositoryId: string): Promise<Item[]> {
    const uri = `${this.prefix}/trash/files?repoId=${repositoryId}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getTags(businessId?: string): Promise<Tag[]> {
    let uri = `${this.prefix}/tags`;
    if (businessId) {
      uri += `?businessId=${businessId}`;
    }
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  createTag(name: string, businessId: string): Promise<Tag> {
    const uri = `${this.prefix}/tags`;
    const body = JSON.stringify({
      name,
      businessId,
    });
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  updateTag(id: string, name: string): Promise<Response> {
    const uri = `${this.prefix}/tags/${id}`;
    const body = JSON.stringify({
      name,
    });
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body,
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  createRepositoryTagMapping(repoId: string, tagId: string): Promise<Tag> {
    const uri = `${this.prefix}/repositories/${repoId}/tags/${tagId}`;
    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  deleteRepositoryTagMapping(repoId: string, tagId: string): Promise<Response> {
    const uri = `${this.prefix}/repositories/${repoId}/tags/${tagId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response);
  }

  getBusinesses(): Promise<Business[]> {
    const uri = `${this.prefix}/businesses`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRoles(): Promise<Role[]> {
    const uri = `${this.prefix}/roles`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getRole(id: string): Promise<Role> {
    const uri = `${this.prefix}/roles/${id}`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  createRole(
    name: string,
    description: string,
    systemDefined: boolean,
    restricted: boolean,
    active: boolean,
    businessId: string,
    permissions: string[],
  ): Promise<Role> {
    const uri = `${this.prefix}/roles`;
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        name,
        description,
        systemDefined,
        restricted,
        active,
        businessId,
        permissions,
      }),
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  updateRole(role: Role): Promise<Response> {
    const uri = `${this.prefix}/roles/${role.id}`;
    const options = {
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(role),
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  assignRole(userId: string, roleId: string): Promise<Response> {
    const uri = `${this.prefix}/users/${userId}/roles/${roleId}`;
    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  removeRole(userId: string, roleId: string): Promise<Response> {
    const uri = `${this.prefix}/users/${userId}/roles/${roleId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getUserRepositories(userId: string): Promise<Repository[]> {
    const uri = `${this.prefix}/users/${userId}/repositories`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getUserRoles(userId: string): Promise<Role[]> {
    const uri = `${this.prefix}/users/${userId}/roles`;
    return fetch(uri)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  getZipDownloadUrl(ids: string[]): Promise<string> {
    const uri = `${this.prefix}/files/zip`;
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ ids }),
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((json: { url: string }) => json.url);
  }

  preregisterUser(email: string, displayName?: string): Promise<string> {
    const uri = `${this.prefix}/users/preregister`;
    const options = {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({ email, displayName }),
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json())
      .then((json: { id: string }) => json.id);
  }

  blockUser(userId: string): Promise<Response> {
    const uri = `${this.prefix}/users/${userId}/lock`;
    const options = {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  unBlockUser(userId: string): Promise<Response> {
    const uri = `${this.prefix}/users/${userId}/lock`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  deleteUser(userId: string): Promise<Response> {
    const uri = `${this.prefix}/users/${userId}`;
    const options = {
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response));
  }

  getDomains(businessId: string): Promise<Domains> {
    const uri = `${this.prefix}/external?businessId=${businessId}`;
    const options = {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };
    return fetch(uri, options)
      .then((response: Response) => FetchClient.handleError(response))
      .then((response: Response) => response.json());
  }

  /**
   * Throws a MetadataError if the given response is not ok.
   *
   * @param response A Response
   * @return The given response
   * @throws A MetadataError or ExtendedMetadataError if the response is not ok
   */
  private static async handleError(response: Response): Promise<Response> {
    if (!response.ok) {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.startsWith('application/json')) {
        const json = await response.json();
        if (json.code && json.description && json.causes) {
          throw new ExtendedMetadataError(json.description, response, json);
        }
        if (!json.code && json.description) {
          throw new MetadataError(json.description, response);
        }
      }
      throw new MetadataError(response.statusText, response);
    }
    return response;
  }
}

export default FetchClient;
