/** @module store/domains */
import MetadataService, { Domains } from 'services/metadata';
import { AppDispatch } from 'store/types';
import {
  DomainsGetRequestAction,
  DomainsGetSuccessAction,
  DomainsGetErrorAction,
  DomainsActionType,
} from './types';

/**
 * Creates a get domains request action.
 * @return A get domains request action.
 */
export const getDomainsRequest = (): DomainsGetRequestAction => ({
  type: DomainsActionType.DOMAINS_GET_REQUEST,
});

/**
 * Creates a get domains success action.
 * @param domains The current domains
 * @return A get domains success action.
 */
export const getDomainsSuccess = (domains: Domains): DomainsGetSuccessAction => ({
  type: DomainsActionType.DOMAINS_GET_SUCCESS,
  payload: {
    domains,
  },
});

/**
 * Creates a get domains error action.
 * @param error An error
 * @return A get domains error action.
 */
export const getDomainsError = (error: Error): DomainsGetErrorAction => ({
  type: DomainsActionType.DOMAINS_GET_ERROR,
  payload: {
    error,
  },
});

/**
 * A thunk that gets domains.
 * @param businessId A business ID
 * @return A thunk that gets domains
 */
export function getDomains(businessId: string) {
  return async (dispatch: AppDispatch): Promise<void> => {
    dispatch(getDomainsRequest());
    try {
      const domains = await new MetadataService().getDomains(businessId);
      dispatch(getDomainsSuccess(domains));
    } catch (error) {
      dispatch(getDomainsError(error));
    }
  };
}
