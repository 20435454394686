/** @module components/AnnouncementsBanner */
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Announcement } from 'services/metadata';
import Banner from 'components/Banner';
import { Props } from './types';
import './styles.scss';

function AnnouncementsBanner(props: Props): JSX.Element | null {
  const {
    getAnnouncements,
    announcements,
    closedBannerIds,
    setBannerClosed,
    bannerContent,
  } = props;

  const [cookies, setCookie] = useCookies(['announcements']);

  const unclosedAnnouncements = announcements.filter(
    (announcement) => !closedBannerIds.includes(announcement.id),
  );

  const childrenBannerVisible = bannerContent && !closedBannerIds.includes(bannerContent.bannerId);

  const [announcementOrContentVisible, setAnnouncementOrContentVisible] = useState(
    Boolean(unclosedAnnouncements.length > 0 || childrenBannerVisible),
  );

  /**
   * Gets announcements when the component mounts.
   */
  useEffect(() => {
    getAnnouncements();
    const closedBanners = cookies.announcements || [];
    closedBanners.forEach((bannerId: string) => {
      setBannerClosed(bannerId);
    });
  }, [getAnnouncements]);

  /**
   * Sets the banner/s to visible when new announcements arrive or when receiving children prop.
   */
  useEffect(() => {
    setAnnouncementOrContentVisible(Boolean(unclosedAnnouncements.length > 0
      || childrenBannerVisible));
  }, [unclosedAnnouncements, childrenBannerVisible]);

  /**
   * Saves closed banner id/s in cookie.
   */
  useEffect(() => {
    if (closedBannerIds.length !== 0) {
      const date = new Date();
      date.setTime(date.getTime() + 24 * 28 * 3600 * 1000);
      setCookie('announcements', closedBannerIds, { path: '', expires: date });
    }
  }, [closedBannerIds]);

  const handleCloseBanner = (bannerId: string): void => {
    setBannerClosed(bannerId);
    setAnnouncementOrContentVisible(false);
  };

  if (!announcementOrContentVisible) {
    return null;
  }

  return (
    <>
      { unclosedAnnouncements.map((announcement: Announcement) => (
        <Banner
          onClose={(): void => handleCloseBanner(announcement.id)}
          data-test-id="announcements-banner"
          key={`announcement-banner-${announcement.id}`}
          hasAlertIcon
          className="AnnouncementsBanner"
        >
          <div className="banner-text-container">
            <ul>
              <li className="banner-text" key={announcement.id}>
                {announcement.summary}
              </li>
            </ul>
          </div>
        </Banner>
      ))}

      {childrenBannerVisible && bannerContent && (
        <Banner
          onClose={(): void => handleCloseBanner(bannerContent.bannerId)}
          data-test-id="content-banner"
          key={`content-banner-${bannerContent.bannerId}`}
          hasAlertIcon
          className="AnnouncementsBanner"
        >
          <div className="banner-text-container">
            <ul>
              <li className="banner-text" key={bannerContent && bannerContent.bannerId}>
                {bannerContent && bannerContent.content}
              </li>
            </ul>
          </div>
        </Banner>
      )}
    </>
  );
}

export default AnnouncementsBanner;
