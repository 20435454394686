import {
  BusinessPermissions, BusinessPermissionsBundle, GlobalPermissions, GlobalPermissionsBundle,
} from 'services/metadata/types/Permissions';
import i18n from '../../i18n';
import { BusinessPermissionsInfo, GlobalPermissionsInfo } from './types';


export const globalPermissionDetails: { [key in GlobalPermissions]: string } = {
  GLOBAL_ASSIGN_ROLE: i18n.t('ManageAdminRolesForm.globalAssignRole', 'User can assign roles to other REDshare users, irrespective of what business they belong to.'),
  GLOBAL_UPDATE_ROLE: i18n.t('ManageAdminRolesForm.globalUpdateRole', 'User can create or update any roles.'),
  GLOBAL_FETCH_USER_REPOSITORIES: i18n.t('ManageAdminRolesForm.globalFetchRepo', 'User can access the list of repositories of other REDshare users.'),
  GLOBAL_LIST_REPOSITORY_COLLABORATORS: i18n.t('ManageAdminRolesForm.globalListCollab', 'User can access the list of collaborators of any repository'),
  GLOBAL_INVITE_COLLABORATOR: i18n.t('ManageAdminRolesForm.globalInviteCollab', 'User can invite another user to collaborate on any repository. The collaborator must still pass the normal checks for entitlement eligibility.'),
  GLOBAL_REMOVE_COLLABORATOR: i18n.t('ManageAdminRolesForm.global.rmvCollab', 'User can remove a collaborator from any repository.'),
  GLOBAL_UPDATE_COLLABORATOR: i18n.t('ManageAdminRolesForm.globalUpdateCollab', 'User can update any collaborator’s role.'),
  GLOBAL_DELETE_REPOSITORY: i18n.t('ManageAdminRolesForm.globalDeleteRepo', 'User can delete any repository.'),
  GLOBAL_RENAME_REPOSITORY: i18n.t('ManageAdminRolesForm.globalRenameRepo', 'User can rename any repository.'),
  GLOBAL_EVICT_REPOSITORY: i18n.t('ManageAdminRolesForm.globalEvictRepo', 'User can extract any repository.'),
  GLOBAL_LIST_USER_ROLES: i18n.t('ManageAdminRolesForm.globalListUserRoles', 'User can access the list of roles of other REDshare users.'),
  GLOBAL_LOCK_USER: i18n.t('ManageAdminRolesForm.globalLockUser', 'User can block other REDshare users.'),
  GLOBAL_UNLOCK_USER: i18n.t('ManageAdminRolesForm.globalUnlockUser', 'User can unblock other REDshare users.'),
  GLOBAL_DELETE_USER: i18n.t('ManageAdminRolesForm.globalDeleteUser', 'User can delete other REDshare users.'),
  GLOBAL_ADD_EMAIL_DOMAIN: i18n.t('ManageAdminRolesForm.globalAddEmailDomain', 'User can add email domains globally.'),
  GLOBAL_REMOVE_EMAIL_DOMAIN: i18n.t('ManageAdminRolesForm.globalRemoveEmailDomain', 'User can remove email domains globally.'),
  GLOBAL_LIST_EMAIL_DOMAINS: i18n.t('ManageAdminRolesForm.globalListEmailDomains', 'User can view the list of email domains globally.'),
  GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH: i18n.t('ManageAdminRolesForm.globalToggleRepoSearch', 'User can enable or disable full-text search for repositories globally.'),
  GLOBAL_DELETE_TAG: i18n.t('ManageAdminRolesForm.globalDeleteTag', 'User can delete tags from repositories globally.'),
  GLOBAL_RENAME_TAG: i18n.t('ManageAdminRolesForm.globalRenameTag', 'User can rename tags used in repositories globally.'),
  GLOBAL_ADD_TAG_TO_REPOSITORY: i18n.t('ManageAdminRolesForm.globalAddTagToRepo', 'User can add tags to repositories globally.'),
  GLOBAL_REMOVE_TAG_FROM_REPOSITORY: i18n.t('ManageAdminRolesForm.globalRemoveTagFromRepo', 'User can remove tags from repositories globally.'),
  GLOBAL_CREATE_GROUP: i18n.t('ManageAdminRolesForm.globalCreateGroup', 'User can create groups globally.'),
  GLOBAL_UPDATE_GROUP: i18n.t('ManageAdminRolesForm.globalUpdateGroup', 'User can update existing groups globally.'),
  GLOBAL_DELETE_GROUP: i18n.t('ManageAdminRolesForm.globalDeleteGroup', 'User can delete groups globally.'),
  GLOBAL_LIST_GROUPS: i18n.t('ManageAdminRolesForm.globalListGroups', 'User can view the list of groups globally.'),
  GLOBAL_CREATE_REPOSITORY_SNAPSHOT: i18n.t('ManageAdminRolesForm.globalCreateRepoSnapshot', 'User can create snapshots of repositories globally.'),
  GLOBAL_LIST_REPOSITORY_SNAPSHOTS: i18n.t('ManageAdminRolesForm.globalListRepoSnapshots', 'User can view the list of snapshots for repositories globally.'),
};

export const businessPermissionDetails: { [key in BusinessPermissions]: string } = {
  BUSINESS_ASSIGN_ROLE: i18n.t('ManageAdminRolesForm.businessAssignRole', 'User can assign business roles to other REDshare users belonging to the same business that of the role.'),
  BUSINESS_UPDATE_ROLE: i18n.t('ManageAdminRolesForm.businessUpdateRole', 'User can create or update roles that share the same business.'),
  BUSINESS_FETCH_USER_REPOSITORIES: i18n.t('ManageAdminRolesForm.businessFetchRepo', 'User can access the list of repositories of other REDshare users of the same business.'),
  BUSINESS_LIST_REPOSITORY_COLLABORATORS: i18n.t('ManageAdminRolesForm.businessListCollab', 'User can access the list of collaborators of repositories of the same business.'),
  BUSINESS_INVITE_COLLABORATOR: i18n.t('ManageAdminRolesForm.businessInviteCollab', 'User can invite another user to collaborate on repositories of the same business. The collaborator must still pass the normal checks for entitlement eligibility.'),
  BUSINESS_REMOVE_COLLABORATOR: i18n.t('ManageAdminRolesForm.businessRmvCollab', 'User can remove a collaborator from repositories of the same business.'),
  BUSINESS_UPDATE_COLLABORATOR: i18n.t('ManageAdminRolesForm.businessUpdateCollab', 'User can update any collaborator’s role on repositories belonging to the same business.'),
  BUSINESS_DELETE_REPOSITORY: i18n.t('ManageAdminRolesForm.businessDeleteRepo', 'User can delete repository belonging to the same business.'),
  BUSINESS_RENAME_REPOSITORY: i18n.t('ManageAdminRolesForm.businessRenameRepo', 'User can rename repository belonging to the same business.'),
  BUSINESS_EVICT_REPOSITORY: i18n.t('ManageAdminRolesForm.businessEvictRepo', 'User can extract repository belonging to the same business.'),
  BUSINESS_LIST_USER_ROLES: i18n.t('ManageAdminRolesForm.businessListUserRoles', 'User can access the list of roles of other REDshare users belonging to the same business.'),
  BUSINESS_LOCK_USER: i18n.t('ManageAdminRolesForm.businessLockUser', 'User can block other REDshare users belonging to the same business.'),
  BUSINESS_UNLOCK_USER: i18n.t('ManageAdminRolesForm.businessUnlockUser', 'User can unblock other REDshare users belonging to the same business.'),
  BUSINESS_DELETE_USER: i18n.t('ManageAdminRolesForm.businessDeleteUser', 'User can delete other REDshare users belonging to the same business.'),
  BUSINESS_ADD_EMAIL_DOMAIN: i18n.t('ManageAdminRolesForm.businessAddEmailDomain', 'User can add email domains to a business.'),
  BUSINESS_REMOVE_EMAIL_DOMAIN: i18n.t('ManageAdminRolesForm.businessRemoveEmailDomain', 'User can remove email domains from a business.'),
  BUSINESS_LIST_EMAIL_DOMAINS: i18n.t('ManageAdminRolesForm.businessListEmailDomains', 'User can view the list of email domains associated with a business.'),
  BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH: i18n.t('ManageAdminRolesForm.businessToggleRepoSearch', 'User can enable or disable full-text search for repositories within the business.'),
  BUSINESS_DELETE_TAG: i18n.t('ManageAdminRolesForm.businessDeleteTag', 'User can delete tags from repositories within the business.'),
  BUSINESS_RENAME_TAG: i18n.t('ManageAdminRolesForm.businessRenameTag', 'User can rename tags used in repositories within the business.'),
  BUSINESS_ADD_TAG_TO_REPOSITORY: i18n.t('ManageAdminRolesForm.businessAddTagToRepo', 'User can add tags to repositories within the business.'),
  BUSINESS_REMOVE_TAG_FROM_REPOSITORY: i18n.t('ManageAdminRolesForm.businessRemoveTagFromRepo', 'User can remove tags from repositories within the business.'),
  BUSINESS_CREATE_GROUP: i18n.t('ManageAdminRolesForm.businessCreateGroup', 'User can create groups within the business.'),
  BUSINESS_UPDATE_GROUP: i18n.t('ManageAdminRolesForm.businessUpdateGroup', 'User can update existing groups within the business.'),
  BUSINESS_DELETE_GROUP: i18n.t('ManageAdminRolesForm.businessDeleteGroup', 'User can delete groups within the business.'),
  BUSINESS_LIST_GROUPS: i18n.t('ManageAdminRolesForm.businessListGroups', 'User can view the list of groups within the business.'),
  BUSINESS_CREATE_REPOSITORY_SNAPSHOT: i18n.t('ManageAdminRolesForm.businessCreateRepoSnapshot', 'User can create snapshots of repositories within the business.'),
  BUSINESS_LIST_REPOSITORY_SNAPSHOTS: i18n.t('ManageAdminRolesForm.businessListRepoSnapshots', 'User can view the list of snapshots for repositories within the business.'),
};

export const globalPermissionBundleDetails:
{ [key in GlobalPermissionsBundle]: GlobalPermissionsInfo } = {
  GLOBAL_MANAGE_ADMIN_ROLES: {
    description: i18n.t('ManageAdminRolesForm.globalManageAdminRoles', 'User can view, assign & update any admin roles irrespective of what business they belong to.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_LIST_USER_ROLES,
      GlobalPermissions.GLOBAL_ASSIGN_ROLE,
      GlobalPermissions.GLOBAL_UPDATE_ROLE,
    ],
  },
  GLOBAL_FETCH_USER_REPOSITORIES: {
    description: i18n.t('ManageAdminRolesForm.globalFetchUserRepositories', 'User can access the list of repositories of other REDshare users.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_FETCH_USER_REPOSITORIES,
    ],
  },
  GLOBAL_VIEW_COLLABORATORS: {
    description: i18n.t('ManageAdminRolesForm.globalViewCollaborators', 'User can access the list of collaborators of any repository.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_LIST_REPOSITORY_COLLABORATORS,
    ],
  },
  GLOBAL_MANAGE_COLLABORATORS: {
    description: i18n.t('ManageAdminRolesForm.globalManageCollaborators', 'User can remove collaborators, edit collaborator roles, invite new collaborators to any repository, and reassign repositories. (The collaborator must still pass the normal checks for entitlement eligibility.)'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_INVITE_COLLABORATOR,
      GlobalPermissions.GLOBAL_REMOVE_COLLABORATOR,
      GlobalPermissions.GLOBAL_UPDATE_COLLABORATOR,
    ],
  },
  GLOBAL_MANAGE_REPOSITORY: {
    description: i18n.t('ManageAdminRolesForm.globalManageRepository', 'User can delete, rename, & extract the repository owner of any repository.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_DELETE_REPOSITORY,
      GlobalPermissions.GLOBAL_RENAME_REPOSITORY,
      GlobalPermissions.GLOBAL_EVICT_REPOSITORY,
    ],
  },
  GLOBAL_BLOCK_UNBLOCK_USER: {
    description: i18n.t('ManageAdminRolesForm.globalBlockUnblockUser', 'User can block & unblock other REDshare users.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_LOCK_USER,
      GlobalPermissions.GLOBAL_UNLOCK_USER,
    ],
  },
  GLOBAL_DELETE_USER: {
    description: i18n.t('ManageAdminRolesForm.globalDeleteUser', 'User can delete other REDshare users.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_DELETE_USER,
    ],
  },
  GLOBAL_MANAGE_EMAIL_DOMAINS: {
    description: i18n.t('ManageAdminRolesForm.globalManageEmailDomains', 'User can add and remove email domains globally.'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_ADD_EMAIL_DOMAIN,
      GlobalPermissions.GLOBAL_REMOVE_EMAIL_DOMAIN,
    ],
  },
  GLOBAL_VIEW_EMAIL_DOMAINS: {
    description: i18n.t('ManageAdminRolesForm.globalViewEmailDomains', 'User can view the list of email domains globally.'),
    isVisible: true,
    permissions: [
      GlobalPermissions.GLOBAL_LIST_EMAIL_DOMAINS,
    ],
  },
  GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH: {
    description: i18n.t('ManageAdminRolesForm.globalToggleRepositoryFullTextsearch', 'User can enable or disable full-text search for repositories globally.'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH,
    ],
  },
  GLOBAL_MANAGE_TAGS: {
    description: i18n.t('ManageAdminRolesForm.globalManageTags', 'User can view, create, add, rename, remove, and delete tags from repositories globally.'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_DELETE_TAG,
      GlobalPermissions.GLOBAL_RENAME_TAG,
      GlobalPermissions.GLOBAL_ADD_TAG_TO_REPOSITORY,
      GlobalPermissions.GLOBAL_REMOVE_TAG_FROM_REPOSITORY,
    ],
  },
  GLOBAL_MANAGE_GROUPS: {
    description: i18n.t('ManageAdminRolesForm.globalManageGroups', 'User can create, update, and delete DL/user groups globally.'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_CREATE_GROUP,
      GlobalPermissions.GLOBAL_UPDATE_GROUP,
      GlobalPermissions.GLOBAL_DELETE_GROUP,
    ],
  },
  GLOBAL_LIST_GROUPS: {
    description: i18n.t('ManageAdminRolesForm.globalListGroups', 'User can view the list of DL/user groups globally'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_LIST_GROUPS,
    ],
  },
  GLOBAL_MANAGE_SNAPSHOTS: {
    description: i18n.t('ManageAdminRolesForm.globalManageSnapshots', 'User can create & download snapshots of repositories globally.'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_CREATE_REPOSITORY_SNAPSHOT,
    ],
  },
  GLOBAL_LIST_SNAPSHOTS: {
    description: i18n.t('ManageAdminRolesForm.globalViewSnapshots', 'User can view the list of snapshots for repositories globally.'),
    isVisible: false,
    permissions: [
      GlobalPermissions.GLOBAL_LIST_REPOSITORY_SNAPSHOTS,
    ],
  },
};

export const businessPermissionBundleDetails:
{ [key in BusinessPermissionsBundle ]: BusinessPermissionsInfo } = {
  BUSINESS_MANAGE_ADMIN_ROLES: {
    description: i18n.t('ManageAdminRolesForm.businessManageAdminRoles', 'User can view, assign & update business roles to other REDshare users belonging to the same business as the assigned role.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_LIST_USER_ROLES,
      BusinessPermissions.BUSINESS_ASSIGN_ROLE,
      BusinessPermissions.BUSINESS_UPDATE_ROLE,
    ],
  },
  BUSINESS_FETCH_USER_REPOSITORIES: {
    description: i18n.t('ManageAdminRolesForm.businessFetchUserRepositories', 'User can access the list of repositories of other REDshare users of the same business.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_FETCH_USER_REPOSITORIES,
    ],
  },
  BUSINESS_VIEW_COLLABORATORS: {
    description: i18n.t('ManageAdminRolesForm.businesViewCollaborators', 'User can access the list of collaborators of repositories of the same business.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_LIST_REPOSITORY_COLLABORATORS,
    ],
  },
  BUSINESS_MANAGE_COLLABORATORS: {
    description: i18n.t('ManageAdminRolesForm.businessManageCollaborators', 'User can remove collaborator, edit collaborator roles, invite new collaborators to repositories, and reassign repositories within the same business. (The collaborator must still pass the normal checks for entitlement eligibility.)'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_REMOVE_COLLABORATOR,
      BusinessPermissions.BUSINESS_UPDATE_COLLABORATOR,
      BusinessPermissions.BUSINESS_INVITE_COLLABORATOR,
    ],
  },
  BUSINESS_MANAGE_REPOSITORY: {
    description: i18n.t('ManageAdminRolesForm.businessManageRepository', 'User can delete, rename & extract the repository owner of repositories belonging to the same business.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_DELETE_REPOSITORY,
      BusinessPermissions.BUSINESS_RENAME_REPOSITORY,
      BusinessPermissions.BUSINESS_EVICT_REPOSITORY,
    ],
  },
  BUSINESS_BLOCK_UNBLOCK_USER: {
    description: i18n.t('ManageAdminRolesForm.businessBlockUnblockUser', 'User can block & unblock other REDshare users belonging to the same business.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_LOCK_USER,
      BusinessPermissions.BUSINESS_UNLOCK_USER,
    ],
  },
  BUSINESS_DELETE_USER: {
    description: i18n.t('ManageAdminRolesForm.businessDeleteUser', 'User can delete other REDshare users belonging to the same business.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_DELETE_USER,
    ],
  },
  BUSINESS_MANAGE_EMAIL_DOMAINS: {
    description: i18n.t('ManageAdminRolesForm.businessManageEmailDomains', 'User can add and remove email domains within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_ADD_EMAIL_DOMAIN,
      BusinessPermissions.BUSINESS_REMOVE_EMAIL_DOMAIN,
    ],
  },
  BUSINESS_VIEW_EMAIL_DOMAINS: {
    description: i18n.t('ManageAdminRolesForm.businessViewEmailDomains', 'User can view the list of email domains associated with a business.'),
    isVisible: true,
    permissions: [
      BusinessPermissions.BUSINESS_LIST_EMAIL_DOMAINS,
    ],
  },
  BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH: {
    description: i18n.t('ManageAdminRolesForm.businessToggleRepositoryFullTextSearch', 'User can enable or disable full-text search for repositories within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_TOGGLE_REPOSITORY_FULL_TEXT_SEARCH,
    ],
  },
  BUSINESS_MANAGE_TAGS: {
    description: i18n.t('ManageAdminRolesForm.businessManageTags', 'User can view, create, add, rename, remove, and delete tags within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_DELETE_TAG,
      BusinessPermissions.BUSINESS_RENAME_TAG,
      BusinessPermissions.BUSINESS_ADD_TAG_TO_REPOSITORY,
      BusinessPermissions.BUSINESS_REMOVE_TAG_FROM_REPOSITORY,
    ],
  },
  BUSINESS_MANAGE_GROUPS: {
    description: i18n.t('ManageAdminRolesForm.businessManageGroups', 'User can create, update, and delete DL/user groups within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_CREATE_GROUP,
      BusinessPermissions.BUSINESS_UPDATE_GROUP,
      BusinessPermissions.BUSINESS_DELETE_GROUP,
    ],
  },
  BUSINESS_LIST_GROUPS: {
    description: i18n.t('ManageAdminRolesForm.businessListGroups', 'User can view the list of DL/user groups within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_LIST_GROUPS,
    ],
  },
  BUSINESS_MANAGE_SNAPSHOTS: {
    description: i18n.t('ManageAdminRolesForm.businessManageSnapshots', 'User can create & download snapshots of repositories within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_CREATE_REPOSITORY_SNAPSHOT,
    ],
  },
  BUSINESS_LIST_SNAPSHOTS: {
    description: i18n.t('ManageAdminRolesForm.businessListSnapshots', 'User can view the list of snapshots for repositories within their business.'),
    isVisible: false,
    permissions: [
      BusinessPermissions.BUSINESS_LIST_REPOSITORY_SNAPSHOTS,
    ],
  },
};
