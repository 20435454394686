/** @module components/RestoreFileModal */
import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import RestoreIcon from 'assets/icons/reload.svg';
import ModalAction from '../ModalAction';
import TitleWithIcon from '../TitleWithIcon';
import FolderTree from '../FolderTree';
import { Props } from './types';
import './styles.scss';

export default function RestoreFileModal(props: Props): JSX.Element {
  const {
    afterClose,
    file,
    repositoryId,
    loading,
    disabled,
    buttonText,
  } = props;
  const { t } = useTranslation();
  const [selectedFolderId, setSelectedFolderId] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (submitted && !loading) {
      setVisible(false);
      setSubmitted(false);
    }
  }, [submitted, loading]);

  function handleSelectFolder(folderId: string | null): void {
    setSelectedFolderId(folderId);
  }

  function handleModalOk(): void {
    const { restoreRepositoryItem, restoreSelectedRepositoryItems } = props;
    if (Array.isArray(file)) {
      restoreSelectedRepositoryItems(selectedFolderId);
    } else {
      restoreRepositoryItem(file, selectedFolderId);
    }
    setSubmitted(true);
  }

  function handleModalCancel(): void {
    setVisible(false);
  }

  if (!repositoryId) {
    return <div />;
  }
  let title = '';
  let submitButtonText = '';
  let name = '';
  let count = 0;

  if (Array.isArray(file)) {
    title = t('RestoreFileModal.title.bulk', 'RESTORE ITEMS');
    submitButtonText = t('RestoreFileModal.submit.bulk', 'RESTORE');
    count = file.length;
    name = file[0].name;
  } else {
    title = file.type.toUpperCase() === 'FILE'
      ? t('RestoreFileModal.title.file', 'RESTORE FILE')
      : t('RestoreFileModal.title.folder', 'RESTORE FOLDER');
    submitButtonText = file.type.toUpperCase() === 'FILE'
      ? t('RestoreFileModal.submit.file', 'RESTORE FILE')
      : t('RestoreFileModal.submit.folder', 'RESTORE FOLDER');
    name = file.name;
  }

  return (
    <ModalAction
      data-test-id="modal-action"
      title={(
        <TitleWithIcon data-test-id="modal-title">
          {title}
        </TitleWithIcon>
      )}
      afterClose={afterClose}
      footer={null}
      actionRender={(): JSX.Element => (
        <Button
          type="link"
          onClick={(): void => setVisible(true)}
          data-test-id="open-button"
          disabled={disabled}
          className="button"
          icon={buttonText ? null : <img className="RestoreIcon" src={RestoreIcon} alt="Restore Icon" />}
        >
          {buttonText}
        </Button>
      )}
      cancelHandler={(): void => handleModalCancel()}
      visible={visible}
      className="RestoreFileModal"
    >
      <Form onFinish={handleModalOk} data-test-id="form">
        <div className="destination-description">
          {count <= 1 && (
            <Trans
              i18nKey="RestoreFileModal.whereToRestore"
              values={{ name }}
              data-test-id="modal-description"
            >
              Where would you like to restore
              {' "'}
              <span className="file-name">
                {'{{name}}'}
              </span>
              {'"'}
                ?
            </Trans>
          )}
          {count > 1 && (
            <Trans
              i18nKey="RestoreFileModal.whereToRestoreBulk"
              values={{ count }}
              data-test-id="modal-description"
            >
              Where would you like to restore these
              {' {{count}} '}
              items?
            </Trans>
          )}
        </div>
        <Form.Item
          name="destination"
          rules={[{
            validator: (): Promise<void> => {
              if (selectedFolderId) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t('RestoreFileModal.destination.required', 'Please select a destination')));
            },
          }]}
          validateTrigger={['onSelect']}
        >
          <FolderTree
            repositoryId={repositoryId}
            onSelect={handleSelectFolder}
            data-test-id="folder-tree"
          />
        </Form.Item>
        <Form.Item className="ant-form-item--last-item ant-form-item--form-buttons">
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            data-test-id="submit-button"
          >
            {submitButtonText}
          </Button>
        </Form.Item>
      </Form>
    </ModalAction>
  );
}
