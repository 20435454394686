/** @module components/DomainsPage */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List } from 'antd';
import { history } from 'utilities/history';
import queryString from 'query-string';
import DashboardPage from '../DashboardPage';
import Loader from '../Loader';
import { Props } from './types';
import './styles.scss';

/**
 * Displays the domains
 */
function DomainsPage(props: Props): JSX.Element {
  const {
    getDomains,
    loading,
    error,
    me,
    domains,
  } = props;
  const { t } = useTranslation();
  const businessId = me.user ? me.user.business.id : '';

  useEffect(() => {
    getDomains(businessId);
  }, [getDomains]);

  if (loading) {
    return <Loader loading data-test-id="loader" />;
  }
  const { search } = history.location;
  const parsedQueryString = queryString.parse(search);
  const goBackUrl = parsedQueryString.goBackUrl ? parsedQueryString.goBackUrl.toString() : null;
  const getUplink = (): string => (goBackUrl || '/');


  return (
    <div className="DomainsPage">
      <DashboardPage
        title={t('DomainsPage.title', 'Appoved Business Domains')}
        data-test-id="page"
        upLink={getUplink()}
      >
        {(error || !domains.domains) && (
          <div className="error-message" data-test-id="error-message">
            <h2>
              {t('DomainsPage.errorMessage', 'Insufficient permissions')}
            </h2>
            <Button
              type="primary"
              onClick={(): void => getDomains(businessId)}
              data-test-id="retry-button"
            >
              {t('DomainsPage.retry', 'Try Again')}
            </Button>
          </div>
        )}
        {!error && domains.domains && (
          <List
            className="ListContainer"
            dataSource={domains.domains.sort((a, b) => a.domain.localeCompare(b.domain))}
            grid={{ gutter: 16, column: 4 }}
            renderItem={(item): JSX.Element => (
              <List.Item key={item.domainId}>
                {item.domain}
              </List.Item>
            )}
            data-test-id="domains-list"
          />
        )}
      </DashboardPage>
    </div>
  );
}

export default DomainsPage;
