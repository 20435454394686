/** @module store/domains */
import { AnyAction } from 'redux';
import {
  DomainsActionType,
  DomainsState,
} from './types';

export const DEFAULT_STATE: DomainsState = {
  business: undefined,
  businessId: undefined,
  domains: [],
};

/**
 * Reduces the given state and action to a new state.
 * @param state The current state
 * @param action An action
 * @return A new state
 */
export default function reducer(
  state: DomainsState = DEFAULT_STATE,
  action: AnyAction,
): DomainsState {
  switch (action.type) {
    case DomainsActionType.DOMAINS_GET_SUCCESS: {
      const { domains } = action.payload;
      return Array.isArray(domains) ? domains[0] : domains;
    }

    default:
      return state;
  }
}
