/** @module components/DomainsPage */
import { connect } from 'react-redux';
import { GlobalState, AppDispatch } from 'store/types';
import { selectLoading } from 'store/loading/selectors';
import { selectError } from 'store/error/selectors';
import { getDomains } from 'store/domains/actions';
import DomainsPage from './component';
import {
  DispatchProps,
  StateProps,
} from './types';

export const mapStateToProps = (state: GlobalState): StateProps => ({
  loading: selectLoading(state.loading, 'DOMAINS_GET'),
  error: selectError(state.error, 'DOMAINS_GET'),
  me: state.me,
  domains: state.domains,
});

export const mapDispatchToProps = (dispatch: AppDispatch): DispatchProps => ({
  getDomains: (businessId: string): void => { dispatch(getDomains(businessId)); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DomainsPage);
