/** @module store/domains */
import { Action } from 'redux';
import { Domains } from 'services/metadata';

export enum DomainsActionType {
  DOMAINS_GET_REQUEST = 'DOMAINS_GET_REQUEST',
  DOMAINS_GET_SUCCESS = 'DOMAINS_GET_SUCCESS',
  DOMAINS_GET_ERROR = 'DOMAINS_GET_ERROR',
}

export type DomainsGetRequestAction = Action<
  DomainsActionType.DOMAINS_GET_REQUEST
>;

export interface DomainsGetSuccessAction extends Action<
  DomainsActionType.DOMAINS_GET_SUCCESS
> {
  payload: {
    domains: Domains;
  };
}

export interface DomainsGetErrorAction extends Action<
  DomainsActionType.DOMAINS_GET_ERROR
> {
  payload: {
    error: Error;
  };
}

export type DomainsAction = DomainsGetRequestAction
                          | DomainsGetSuccessAction
                          | DomainsGetErrorAction;

export type DomainsState = Domains;
