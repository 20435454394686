/** @module components/DeleteUserPage */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ConfirmNavigationModal from 'components/ConfirmNavigationModal';
import { history } from 'utilities/history';
import DeleteUserSteps from 'components/DeleteUserSteps';
import DashboardPage from 'components/DashboardPage';
import './styles.scss';
import {
  Button,
  Card,
  Modal,
  Typography,
} from 'antd';
import {
  Info, CheckCircleOutline, InfoOutlined, Help,
} from '@material-ui/icons';
import { BusinessPermissions, GlobalPermissions } from 'services/metadata/types/Permissions';
import Loader from 'components/Loader';
import DashboardPageError from 'components/DashboardPageError';
import { ErrorType } from 'components/DashboardPageError/types';
import { ReactComponent as DeleteUserImage } from 'assets/images/Delete-User-1.svg';
import { ReactComponent as DeletedUserImage } from 'assets/images/Delete-User-2.svg';
import { appPaths } from 'utilities/routes';
import { Props } from './types';

function DeleteUserPage(props: Props): JSX.Element {
  const {
    selectedUser,
    searchedUsers,
    searchUsers,
    deleteUser,
    getUserRepositories,
    blockUser,
    repositories,
    userId,
    loading,
    me,
    deleteUserLoading,
    setSelectedUser,
    deletedUserId,
    clearDeletedUser,
    userRepositories,
  } = props;

  const getFailed = userRepositories[-1] && userRepositories[-1].callFailed;

  const { t } = useTranslation();
  const ownedRepositories = repositories.filter((repo) => userId === repo.owner.id);

  const [allReposReassigned, setAllReposReassigned] = useState<boolean>(false);

  useEffect(() => {
    clearDeletedUser();
  }, []);

  useEffect(() => {
    getUserRepositories(userId);
  }, [userId, getUserRepositories]);

  useEffect(() => {
    if (searchedUsers.length === 0 && !selectedUser) {
      searchUsers(userId);
    }
  }, [searchUsers, userId, searchedUsers, selectedUser]);

  /**
   * Sets the searched user as the selected user
   */
  useEffect(() => {
    if (searchedUsers.length && !selectedUser) {
      setSelectedUser(searchedUsers[0]);
    }
  }, [setSelectedUser, searchedUsers, selectedUser]);

  const cancelDelete = (): void => {
    if (selectedUser) history.push(`${appPaths.adminUserSearch}?query=${selectedUser.email}`);
  };

  const returnAdminConsole = (): void => {
    clearDeletedUser();
    history.push(`${appPaths.admin}`);
  };

  const confirmDelete = (): void => {
    if (selectedUser) deleteUser(selectedUser.id);
  };

  let canBlockUser = false;

  const { permissions } = me;
  if (permissions) {
    if (selectedUser && selectedUser.business) {
      const userBusiness = selectedUser.business.id;
      const businessPermission = permissions.byBusiness.find(
        (p) => p.businessId === userBusiness,
      );
      // if admin has business permissions for user's business
      if (businessPermission) {
        if (businessPermission.permissions.includes(
          BusinessPermissions.BUSINESS_LOCK_USER,
        )) {
          canBlockUser = true;
        }
      }
      if (permissions.global.length) {
        const { global } = permissions;
        if (global.includes(GlobalPermissions.GLOBAL_LOCK_USER)) {
          canBlockUser = true;
        }
      }
    }
  }

  if (loading) {
    return <Loader loading={loading} data-test-id="loader" />;
  }
  return (
    <div className="DeleteUserPage">
      <DashboardPage
        title={t('DeleteUserPage.title', 'Delete User')}
        upLink={appPaths.adminUserSearch}
      >
        {
          !deletedUserId ? (
            <ConfirmNavigationModal
              data-test-id="confirm-nav-modal"
              when
              title={t('DeleteUserPage.confirmCancel.title', 'Are you sure you want to cancel your work?')}
              content={t('DeleteUserPage.confirmCancel.subTitle', 'This user\'s account will not be deleted from REDshare. They will still be an Owner on repositories that were not successfully reassigned.')}
              okText={t('DeleteUserPage.confirmCancel.button', 'CANCEL')}
              cancelText={t('DeleteUserPage.confirmCancel.cancelButton', 'KEEP WORKING')}
            />
          ) : null
        }
        {selectedUser
          ? (
            <Card
              className="card"
              title={(
                <>
                  <div className="deleting-title">{t('DeleteUserPage.deleteUser.title', 'DELETING')}</div>
                  <div className="user-title">{selectedUser.name}</div>
                </>
              )}
            >
              {
                !deletedUserId && deletedUserId !== selectedUser.id ? (
                  <>
                    <div className="content-container">
                      <div className="left-section">
                        <Card className="footprint-container">
                          <div className="footprint-title-row">
                            <Info className="footprint-icon" />
                            <Typography.Title level={5} className="footprint-title">{t('DeleteUserPage.userFootprints.title', 'User Footprint')}</Typography.Title>
                          </div>
                          <div className="footprints">
                            {getFailed && (
                              <>
                                <div className="footprint-main-line">
                                  <Typography.Text strong className="emphasized-footprint" data-test-id="total-footprint">
                                    {t('DeleteUerPage.userFootprints.forbidden.title', 'Unable to delete this user.')}
                                  </Typography.Text>
                                </div>
                                <div>
                                  <div className="footprint-line">
                                    <Typography.Text strong className="emphasized-footprint">
                                      {t('DeleteUerPage.userFootprints.forbidden.noPermission', 'You do not have permission to see this user\'s repositories.')}
                                    </Typography.Text>
                                  </div>
                                  <div className="footprint-line">
                                    <Typography.Text className="emphasized-footprint">
                                      {t('DeleteUerPage.userFootprints.forbidden.getHelp', 'Ask for help to obtain the proper administrative permissions.')}
                                    </Typography.Text>
                                  </div>
                                </div>
                              </>
                            )}
                            {!getFailed && (
                              <>
                                <div className="footprint-main-line">
                                  <Typography.Text strong className="emphasized-footprint" data-test-id="total-footprint">
                                    {repositories.length}
                                    {t('DeleteUserPage.userFootprints.accessibleRepos', ' Accessible Repositories')}
                                  </Typography.Text>
                                </div>
                                <div>
                                  <div className="footprint-line">
                                    <Typography.Text className="emphasized-footprint">
                                      <b>
                                        {t('DeleteUserPage.userFootprints.owner', 'Owner of ')}
                                        <span data-test-id="owner-footprint">{ownedRepositories.length}</span>
                                      </b>
                                      {t('DeleteUserPage.userFootprints.repos', ' repositories')}
                                    </Typography.Text>
                                  </div>
                                  <div className="footprint-line">
                                    <Typography.Text className="emphasized-footprint">
                                      <b>
                                        {t('DeleteUserPage.userFootprints.sharedCollaborator', 'Shared Collaborator on ')}
                                        <span data-test-id="co-owner-footprint">{repositories.length - ownedRepositories.length}</span>
                                      </b>
                                      {t('DeleteUserPage.userFootprints.repos', ' repositories')}
                                    </Typography.Text>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </Card>
                        {!selectedUser.locked && canBlockUser
                          && (
                            <Card
                              className="block-card"
                              key="block-user"
                              data-test-id="block-user-card"
                            >
                              <div className="block-text">
                                <Help className="question-mark" />
                                {t('DeleteUserPage.blockUser.text', 'Want to temporarily suspend this user instead?')}
                              </div>
                              <Button
                                className="button"
                                type="link"
                                onClick={(): void => {
                                  Modal.confirm({
                                    title: t('DeleteUserPage.confirmBlockUser.title', 'Block {{userName}}?', { userName: selectedUser.name }),
                                    content: t('DeleteUserPage.confirmBlockUser.content', 'Are you sure you want to block this user? This will prevent the user from logging in, but not delete their content. If you change your mind, you can unblock them from their User Actions menu.\n\nAll work to reassign their repositories will be kept.'),
                                    onOk: (): void => blockUser(selectedUser.id),
                                    onCancel: () => { },
                                    okText: t('DeleteUserPage.confirmBlockUser.button', 'BLOCK'),
                                    cancelText: t('DeleteUserPage.confirmBlockUser.cancelButton', 'CANCEL'),
                                    cancelButtonProps: { className: 'ant-btn-ghost' },
                                    closable: true,
                                  });
                                }}
                                data-test-id="block-user-button"
                              >
                                {t('DeleteUserPage.blockUser', 'BLOCK')}
                              </Button>
                            </Card>
                          )}

                        {ownedRepositories.length !== 0 && <DeleteUserImage className="delete-user-image-small" />}
                      </div>

                      {!getFailed && (
                        <div className="center-section">
                          {ownedRepositories.length > 0 ? (
                            <DeleteUserSteps
                              repositories={ownedRepositories}
                              setAllReposReassigned={setAllReposReassigned}
                            />
                          ) : (
                            <div className="no-owner-description">
                              {deleteUserLoading ? (
                                <div className="deleting-info" data-test-id="deleting-user-info">
                                  <InfoOutlined className="deleting-info-icon" />
                                  {t('DeleteUserPage.noOwnerRepo.deletingInfo', 'Almost done! We’re removing this user from repositories. Do not close this window.')}
                                </div>
                              ) : (
                                <Trans i18nKey="DeleteUserPage.noOwnerRepo.description">
                                  <div className="no-owner-text">
                                    This
                                    {' '}
                                    <span className="bold-text">user will now be removed</span>
                                    {' '}
                                    as a collaborator from all of their repositories.
                                    {' '}
                                    Since this user is not
                                    {' '}
                                    an Owner of any repositories, there is no
                                    {' '}
                                    further action needed before deleting.
                                  </div>
                                </Trans>
                              )}
                              <div className="loader-container">
                                <Loader loading={deleteUserLoading} />
                              </div>
                              <DeleteUserImage className={`delete-user-image ${deleteUserLoading ? 'loading' : ''}`} />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="footer-container">
                      {
                        !deleteUserLoading
                        && !getFailed
                        && (
                          <>
                            <Button
                              data-test-id="cancel-button"
                              className="cancel-button"
                              type="default"
                              onClick={cancelDelete}
                            >
                              {t('DeleteUserPage.cancel', 'CANCEL')}
                            </Button>
                            {(ownedRepositories.length === 0 || allReposReassigned) && (
                              <Button
                                data-test-id="delete-button"
                                className="delete-button"
                                type="primary"
                                onClick={(): void => {
                                  Modal.confirm({
                                    title: t('DeleteUserPage.confirmDelete.title', 'Delete {{userName}}?', { userName: selectedUser.name }),
                                    content: t('DeleteUserPage.confirmDelete.content', 'By deleting this user, you are permanently closing their REDshare account. This action cannot be undone.'),
                                    onOk: () => confirmDelete(),
                                    onCancel: () => { },
                                    cancelButtonProps: { className: 'ant-btn-ghost' },
                                    autoFocusButton: null,
                                    cancelText: t('DeleteUserPage.confirmDelete.cancel', 'CANCEL'),
                                    okText: t('DeleteUserPage.confirmDelete.ok', 'DELETE'),
                                  });
                                }}
                              >
                                {t('DeleteUserPage.delete', 'DELETE')}
                              </Button>
                            )}

                          </>
                        )
                      }
                    </div>
                  </>
                ) : (
                  <div className="content-container">
                    <div className="center-section deleted-user-view">
                      <div className="deleted-user-view" data-test-id="deleted-user-info">
                        <DeletedUserImage className="deleted-user-image" />
                        <div className="deleted-user-message">
                          <CheckCircleOutline className="check-icon" />
                          <div className="text">
                            <span>
                              {t('DeleteUserPage.deletedUser.message', 'Done! {{userName}} has been deleted from REDshare.',
                                {
                                  userName: selectedUser.name,
                                })}
                            </span>
                            {
                              ownedRepositories.length ? (
                                <span>
                                  {t('DeleteUserPage.deletedUser.messageRepositoriesReassigned',
                                    '{{repositoriesQuantity}} repositories have also been reassigned to new Owners!',
                                    {
                                      repositoriesQuantity: ownedRepositories.length,
                                    })}
                                </span>
                              ) : null
                            }
                          </div>
                        </div>
                        <Button
                          data-test-id="return-admin-console-button"
                          className="button"
                          type="primary"
                          onClick={returnAdminConsole}
                        >
                          {t('DeleteUserPage.deletedUser.returnAdminConsole', 'RETURN TO ADMIN CONSOLE')}
                        </Button>
                      </div>
                    </div>

                  </div>
                )
              }

            </Card>
          ) : <DashboardPageError errorType={ErrorType.NOT_FOUND} data-test-id="error-page" />}
      </DashboardPage>
    </div>
  );
}
export default DeleteUserPage;
